import Swal from "sweetalert2";
import CryptoJS from 'crypto-js';


import { SECRET_KEY } from "../constant/Constant";

export const isValidEmail =(email)=>{
    let rejex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return rejex.test(email)
}

export const openLink = (url) => window.open(url, '_blank')?.focus();

export const thankYouAlert = () =>{
    Swal.fire({
        title: "Thank you !",
        text: "We will get back you soon !",
        icon: "success"
    });
}

export const appsAlert = (message) =>{
    Swal.fire(message);
}

export const encryptData = (message) =>{ 

   return  CryptoJS.AES.encrypt(message.toString(),SECRET_KEY).toString()
}

export const decryptData = async (message) =>{

    const bytes = await CryptoJS.AES.decrypt(message,SECRET_KEY);
      setTimeout(async()=>{
        const value = await bytes.toString(CryptoJS.enc.Utf8);
        return value;
      },1000)

}